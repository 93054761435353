// Transform all capitalized sentences into a sentence with a capital letter at the beginning

const contentData = {
  fr: {
    title: 'Tout le monde aime jouer!',
    subtitle:
      "Golden Palace Casino est l'ultime casino en ligne en Belgique, tandis que le Golden Palace Sports organise des paris sportifs, petits et grands, depuis plus de 10 ans. Sur Golden Palace Casino & Sports, vous pouvez jouer en ligne, participer à des tournois et trouver les meilleures cotes, ce qui signifie bien sûr plus de chances de gagner. Vous voulez parier sur les sports, jouer au poker en ligne ou au texas hold'em ? Ou préférez-vous les machines à sous en ligne, la roulette en ligne, les dés à jouer ou tout autre jeu de casino ? Vous êtes au bon endroit : Golden Palace est 100% belge, 100% légal et 100% amusant. Et pour ceux qui aiment sortir de leur fauteuil, Golden Palace dispose de dizaines de salles de jeux de grande classe dans notre pays et même à l'étranger, toutes équipées de machines à sous de dernière génération et d'une ambiance de casino que vous n'oublierez jamais. Tentez votre chance !",
    articles: [
      {
        topTitle: 'Les meilleurs jeux de casino en ligne',
        buttonTitle: 'Casino',
        buttonBackgroundColor: 'customGold',
        redirectionUrl: 'B',
        imageLeft: true,
        imageUrl: '/placeholders/casino.webp',
        title: 'Casino en ligne',
        body: "La gamme de jeux proposés au Golden Palace Casino est énorme. Quelle que soit votre humeur, vous trouverez toujours des jeux de dés ou un tournoi de casino qui répond à vos attentes. Laissez la chance être de votre côté et tentez de gagner l'un des nombreux jeux en ligne proposés par les fournisseurs les plus connus. Jouez au blackjack en ligne, au poker omaha ou à la roulette en direct, ou passez des heures de plaisir sur les machines à sous à dés les plus amusantes, tant pour les débutants que pour les experts (dés stratégiques). L'excitation ainsi qu'une expérience de jeu sûre sont garanties à tout moment au Golden Palace. Jouez avec modération et visez le jackpot !",
        smallArticles: [
          {
            title: 'Jouer au Blackjack en direct',
            body: "Vous pouvez jouer au blackjack au casino Golden Palace de plusieurs façons. Chez nous, vous trouverez toutes les variantes possibles de ce classique du casino, qu'il soit en direct ou non. Que vous ne connaissiez le blackjack que de nom ou que vous soyez un vétéran chevronné du jeu, la quête d'une main de 21 est toujours à la fois excitante et relaxante. Venez le découvrir et gagnez de l'argent rapidement avec votre meilleure stratégie !",
            imageUrl: '/placeholders/hub-article-cards.webp'
          },
          {
            title: 'Jouer à la Roulette en direct',
            body: 'Rien ne va plus : la roulette est un succès absolu dans tous les casinos, et là encore, vous êtes au bon endroit au Golden Palace Casino. Nous proposons de nombreuses tables, en direct ou non, et il y a toujours un siège disponible pour vous. Grâce à une gamme de paris équilibrée, les débutants comme les vétérans peuvent profiter de cette expérience de casino indispensable dans un confort optimal. Rouge ou noir, pair ou impair : à vous de choisir !',
            imageUrl: '/placeholders/hub-article-wheel.webp'
          },
          {
            title: 'Jouer à des jeux de machines à sous',
            body: "Outre les jeux de casino classiques que vous connaissez, les jeux de machines à sous ne sont pas en reste pour une expérience amusante. Nous avons tout ce qu'il faut, des jeux conçus dans le style des machines à sous classiques - pensez cerises, cloches et sept - aux jeux de spectacle fantastiques dans des thèmes et des couleurs étonnamment innovants : le casino Golden Palace offre tout cela, avec des centaines de jeux disponibles jour et nuit !",
            imageUrl: '/placeholders/hub-article-slot.webp'
          }
        ]
      },
      {
        topTitle: 'Pariez sur vos sports préférés',
        buttonTitle: 'Sport',
        buttonBackgroundColor: 'customGreen',
        redirectionUrl: 'F',
        imageLeft: false,
        imageUrl: '/placeholders/sports.webp',
        title: 'Paris sportifs',
        body: "Golden Palace Sports dispose d'une licence officielle pour les paris sportifs, aussi bien en ligne que dans l'une de nos nombreuses librairies Press Shop & More. Choisissez votre sport préféré, placez un pari et bénéficiez des meilleures cotes pour un maximum de chances de gagner. Parier sur le football, les fléchettes, le basket-ball, le tennis, le cyclisme, le volley-ball, le hockey sur glace, le football américain, le snooker, le MMA, les sports électroniques... Au Golden Palace, vous vivrez l'émotion de votre vie lorsque l'équipe sur laquelle vous avez parié repartira avec un bénéfice. Avons-nous mentionné que nous avons les meilleures cotes ? Avec ça, vous en savez immédiatement assez...",
        smallArticles: [
          {
            title: 'Paris sur le Football',
            body: "Si vous suivez de près le football national ou international, vous vivrez cette passion encore plus intensément en pariant sur certaines équipes. Vous pouvez même le faire en direct, lorsqu'un match est déjà en cours. Pariez sur le nombre de buts ou sur l'équipe gagnante et, ce faisant, ajoutez une dimension supplémentaire à votre sympathie pour les différents matchs. Grâce à nos cotes extrêmement intéressantes, le foot est devenu une fête encore plus grande !",
            imageUrl: '/placeholders/hub-article-1.webp'
          },
          {
            title: 'Paris sur le Cyclisme',
            body: "Vous adorez le cyclisme ? Alors savez que vous pouvez également y participer avec succès. Pas en montant sur un vélo, mais en pariant sur le cycliste qui, selon vous, franchira la ligne d'arrivée en premier. Heureusement, vous n'avez pas besoin d'endurance pour cela, mais vous avez besoin de connaître le bon coureur ou la bonne équipe. Êtes-vous un tel expert ? Ou êtes-vous un cavalier chanceux né ? Alors... ",
            imageUrl: '/placeholders/hub-article-2.webp'
          },
          {
            title: 'Paris sur le Basket-ball',
            body: 'Vous aimez les dunks, les lay-ups et les tirs à trois points ? Possédé par les rebonds, les interceptions, les play-offs ? Vous restez au courant des derniers développements dans les ligues belges, européennes et/ou américaines ? Sur Golden Palace, vivez votre rêve personnel de basket-ball comme nulle part ailleurs. Faites les bons paris et gagnez !',
            imageUrl: '/placeholders/hub-article-3.webp'
          }
        ]
      }
    ],
    advantages: {
      title: 'Pourquoi choisir Golden Palace Casino & Sports ?',
      cards: [
        {
          imageUrl: '/icons/promo.svg',
          title: 'Promos Constantes',
          body: "Chez Golden Palace, les promotions pleuvent ! Elles sont toujours très appréciées de nos clients fidèles et ces gens savent très bien pourquoi : il y a invariablement une grosse cagnotte ! Notre conseil : inscrivez-vous comme l'éclair à nos newsletters..."
        },
        {
          imageUrl: '/icons/doublecards.svg',
          title: 'Golden Odds',
          body: "Golden Palace est connu pour ses GoldenOdds. Il s'agit de cotes 'boostées' qui augmentent vos gains potentiels sans augmenter le risque. En bref, grâce à nos GoldenOdds, vous pouvez gagner de plus en plus facilement sur Golden Palace !"
        },
        {
          imageUrl: '/icons/personalCard.svg',
          title: 'Tournois passionnants',
          body: 'Golden Palace organise des tournois quotidiens avec des prix très importants, tant pour les jeux de casino que pour les paris sportifs. Ne manquez donc pas de profiter de notre offre étendue et de qualité !'
        },
        {
          imageUrl: '/icons/hp.svg',
          title: 'Très accessible',
          body: "Sur Golden Palace, pas besoin de passer par une longue procédure pour jouer ou parier. Il suffit de s'inscrire, de passer la vérification de l'âge et d'entrer dans un environnement de jeu 100% sûr et légal. Golden Palace répond naturellement aux exigences les plus strictes de la Commission belge des jeux de hasard."
        },
        {
          imageUrl: '/icons/heart.svg',
          title: 'Restez informé',
          body: "Grâce à nos newsletters et à nos différents canaux de médias sociaux, vous resterez au courant des derniers jeux, des derniers développements, des promos les plus intéressantes, des GoldenOdds, etc. Gardez un œil sur votre boîte mail et consultez régulièrement nos comptes pour découvrir encore plus d'opportunités !"
        },
        {
          imageUrl: '/icons/slot.svg',
          title: 'Nous aimons vous aider!',
          body: "Notre service d'assistance est toujours prêt à répondre à vos questions. Vous avez besoin d'aide pour un jeu particulier ? Ou des informations sur nos services ? En tant qu'entreprise familiale, nous sommes plus proches de nos joueurs que quiconque. Que ce soit en ligne ou dans nos casinos terrestres, le client passe avant tout !"
        }
      ]
    },
    gamingHall: {
      imageUrl: '/placeholders/gaming-halls.webp',
      title: 'Salles de jeux Golden Palace',
      body: "Où que vous soyez, il y a toujours un Golden Palace à proximité. Immergez-vous et vivez des heures inoubliables dans l'une de nos dizaines de salles de jeux en Belgique et en France. Mardi après-midi, vendredi matin, dimanche soir, ... Chaque Golden Palace est ouvert 24/24 et prêt à vous accueillir avec classe à chaque fois.",
      buttonTitle: 'Découvrir',
      redirectionUrl: '/halls'
    }
  },
  en: {
    title: 'Everybody loves to play!',
    subtitle:
      "Golden Palace Casino is the ultimate online casino in Belgium, while Golden Palace Sports has been hosting both small and large sports betting events for more than 10 years. At Golden Palace Casino & Sports, you can gamble online and enter tournaments. You'll also find the best odds, which of course means more chances of winning. Want to bet on sports, play online blackjack or texas hold'em? Or do you prefer online slot games, online roulette, dice games or any other casino game? You've come to the only right place: Golden Palace is 100% Belgian, 100% legal and 100% fun. And for those who like to get out of their couches, Golden Palace has dozens of highly luxurious gaming rooms in our country and even beyond. These are all equipped with the latest generation of machines and breathe a casino atmosphere you'll never forget. Try your luck!",
    articles: [
      {
        topTitle: 'The best online casino games',
        buttonTitle: 'Casino',
        buttonBackgroundColor: 'customGold',
        redirectionUrl: 'B',
        imageLeft: true,
        imageUrl: '/placeholders/casino.webp',
        title: 'Online Casino',
        body: 'The range of games on offer at Golden Palace Casino is huge. No matter what mood you are in, you will always find a game or casino tournament that meets your expectations. Let luck be on your side and take a shot at winning one of the many online games from the best-known providers. Play online blackjack, omaha poker or live roulette, or spend hours of fun on the most fun dice slot games, both for beginners and advanced players (strategic dice games). Excitement as well as a safe gaming experience are guaranteed at Golden Palace at all times. Play in moderation and go for the jackpot!',
        smallArticles: [
          {
            title: 'Play Blackjack live',
            body: "Playing blackjack at Golden Palace Casino can be done in numerous ways. With us, you'll find all possible variations of this casino classic, whether it's live or not. No matter whether you know blackjack by name only or are a seasoned veteran of the game, the hunt for the hand of 21 is always both exciting and relaxing. Come discover our games and win money fast with your best strategy!",
            imageUrl: '/placeholders/hub-article-cards.webp'
          },
          {
            title: 'Play Roulette live',
            body: "Rien ne va plus: roulette is an absolute hit in any casino, and here as well you're in the right place at Golden Palace Casino. We offer plenty of tables, live or not, and there's always a seat available for you. Thanks to a balanced betting range, beginners and veterans alike can enjoy this indispensable casino experience in optimum comfort. Red or black, even or odd: the choice is yours!",
            imageUrl: '/placeholders/hub-article-wheel.webp'
          },
          {
            title: 'Play Slot Games',
            body: 'Besides the classic casino games you know, slot games are no slouch for a fun experience either. We have it all, from games designed in the style of classic slot machines – think cherries, bells and sevens – to fantastic spectacle games in surprisingly innovative themes and colours, you’ll find it at Golden Palace Casino, with hundreds of games available day and night!',
            imageUrl: '/placeholders/hub-article-slot.webp'
          }
        ]
      },
      {
        topTitle: 'Bet on your favourite sports',
        buttonTitle: 'Sport',
        buttonBackgroundColor: 'customGreen',
        redirectionUrl: 'F',
        imageLeft: false,
        imageUrl: '/placeholders/sports.webp',
        title: 'Sports betting',
        body: "Golden Palace Sports is officially licensed for sports betting, both online and in one of our many Press Shop & More shops. Choose your favourite sport, place a bet and benefit from the best odds for maximum chances of winning. Betting on football, darts, basketball, tennis, cycling, volleyball, ice hockey, American football, snooker, MMA, eSports... At Golden Palace, you'll experience the thrill of a lifetime when the team you placed a bet on walks away with the win. Did we mention that we offer the best odds in the business? With that, you immediately know enough...",
        smallArticles: [
          {
            title: 'Betting on Football',
            body: 'If you follow national or international football closely, you will experience that passion even more intensely by betting on your preferred teams. You can even do this live, during the match itself. Bet on the number of goals or on the winning team and add an extra dimension to your experience. Thanks to our extremely interesting odds, football becomes an even bigger thing!',
            imageUrl: '/placeholders/hub-article-1.webp'
          },
          {
            title: 'Betting on Cycling',
            body: "Cycling is big business in Belgium, and also you can participate successfully. Not by getting on a bike, but by betting on the cyclist you expect to cross the finish line first. Fortunately, you don't need endurance for that, but what you do need is the right insight. Are you such an expert? Or are you born with such luck? Well then...",
            imageUrl: '/placeholders/hub-article-2.webp'
          },
          {
            title: 'Betting on Basketball',
            body: 'Fan of dunks, lay-ups, and three-pointers? Possessed by rebounds, steals, turnovers, and overtime? Up to date with the latest developments in the Belgian, European and/or American pro leagues? At Golden Palace, live your personal basketball dream like nowhere else. Place the right bets and win big!',
            imageUrl: '/placeholders/hub-article-3.webp'
          }
        ]
      }
    ],
    advantages: {
      title: 'Why chose Golden Palace Casino & Sports?',
      cards: [
        {
          imageUrl: '/icons/promo.svg',
          title: 'Constant promos',
          body: "It's raining promos at Golden Palace, and they are always popular with our loyal customers. Why? Because there is always a big prize pool! Our tip: sign up quick for our promo newsletter..."
        },
        {
          imageUrl: '/icons/doublecards.svg',
          title: 'GoldenOdds',
          body: 'Golden Palace is known for its GoldenOdds. These are boosted odds that increase your potential winnings, while the chance remains the same! In short, thanks to GoldenOdds, you can win more and more easily at Golden Palace...'
        },
        {
          imageUrl: '/icons/personalCard.svg',
          title: 'Exciting tournaments',
          body: 'Golden Palace organizes tournaments with extra-large prize money almost every day, both for casino games and sports betting. So be sure to take advantage of our extensive and high-quality offer!'
        },
        {
          imageUrl: '/icons/hp.svg',
          title: 'Particularyly accessible',
          body: "You don't have to go through a long procedure to bet or play. Register, pass the age check and enter a 100% safe and legal gaming environment. Golden Palace naturally meets the strictest requirements of the Belgian Gaming Commission."
        },
        {
          imageUrl: '/icons/heart.svg',
          title: 'Stay informed',
          body: 'Thanks to our newsletters and various social media channels, you can stay up to date with the latest games, the latest developments, the most interesting promos, GoldenOdds and more. Keep an eye on your mailbox and check our accounts regularly for even more opportunities.'
        },
        {
          imageUrl: '/icons/slot.svg',
          title: 'We love to help you!',
          body: 'Our support service is always ready to answer your questions. Need help with a particular game? Or info about our services? As a family-run business, we are closer to our players than anyone else. Both online and in our land-based casinos, the customer comes first!'
        }
      ]
    },
    gamingHall: {
      imageUrl: '/placeholders/gaming-halls.webp',
      title: 'Golden Palace Gaming Halls',
      body: 'Wherever you are, there is always a Golden Palace nearby. Immerse yourself and experience unforgettable hours in one of our dozens of gaming halls and casinos in Belgium and France. Tuesday afternoon, Friday morning, Sunday night... You’ll always find a Golden Palace near you open and ready to welcome you with class every time.',
      buttonTitle: 'Discover',
      redirectionUrl: '/halls'
    }
  },
  nl: {
    title: 'Iedereen houdt van spelen!',
    subtitle:
      "Golden Palace Casino is het ultieme online casino in België, terwijl Golden Palace Sports al meer dan 10 jaar zowel kleine als grote sportweddenschappen organiseert. Op Golden Palace Casino & Sports kan je online gokken en aan toernooien meedoen. Je vindt er verder de beste odds, wat natuurlijk meer kans op winnen betekent. Wedden op sport, online blackjack of texas hold'em spelen? Of gaat jouw voorkeur uit naar online slotgames, online roulette, dicegames of een andere casinogame? Je bent op de enige juiste plek: Golden Palace is 100% Belgisch, 100% legaal en 100% fun. En voor wie graag uit zijn luie zetel komt: Golden Palace telt tientallen uiterst luxueuze speelzalen in ons land en zelfs daarbuiten. Deze zijn allemaal uitgerust met de nieuwste generatie machines, en ademen een casinosfeer die je nooit meer vergeet. Waag je kans!",
    articles: [
      {
        topTitle: 'De beste online casinogames',
        buttonTitle: 'Casino',
        buttonBackgroundColor: 'customGold',
        redirectionUrl: 'B',
        imageLeft: true,
        imageUrl: '/placeholders/casino.webp',
        title: 'Online Casino',
        body: 'Het aanbod kansspelen op Golden Palace Casino is gigantisch. In welke bui je ook bent, je vindt altijd een game of een casinotornooi dat aan je verwachtingen voldoet. Laat geluk aan jouw kant staan en doe een poging om een van de vele online games van de bekendste providers te winnen. Speel online blackjack, omaha poker of live roulette, of besteed uren plezier aan de leukste diceslotgames, zowel voor beginners als voor gevorderden (strategische dicegames). Spanning, maar ook een veilige spelervaring zijn te allen tijde gegarandeerd op Golden Palace. Speel met mate en ga voor de jackpot!',
        smallArticles: [
          {
            title: 'Blackjack live spelen',
            body: 'Blackjack spelen bij Golden Palace Casino kan op tal van manieren. Bij ons vind je alle mogelijke variaties op deze casinoklassieker, of het nu live is of niet. Het maakt niet uit of je blackjack alleen van naam kent of je een doorgewinterde veteraan van het spel bent, de jacht naar een hand van 21 is altijd zowel spannend als ontspannend. Kom het ontdekken en win snel geld met je beste strategie!',
            imageUrl: '/placeholders/hub-article-cards.webp'
          },
          {
            title: 'Roulette live spelen',
            body: 'Rien ne va plus: roulette is een absolute topper in elk casino, en ook hier zit je goed bij Golden Palace Casino. We bieden tal van tafels, live of niet, en er is altijd een plaats vrij voor jou. Dankzij een gebalanceerd inzetbereik kunnen zowel beginnende spelers als veteranen deze onontbeerlijke casino-ervaring in optimaal comfort beleven. Rood of zwart, even of oneven: aan jou de keuze!',
            imageUrl: '/placeholders/hub-article-wheel.webp'
          },
          {
            title: 'SlotGames spelen',
            body: 'Naast de klassieke casinogames die je kent, zijn slotgames ook niet te versmaden voor een leuke ervaring. We hebben het allemaal, van games ontworpen naar de stijl van klassieke slotmachines – denk kersen, bellen en zevens – tot fantastische spektakelgames in verrassend innoverende thema’s en kleuren: Golden Palace Casino biedt het allemaal aan, met honderden games dag en nacht beschikbaar!',
            imageUrl: '/placeholders/hub-article-slot.webp'
          }
        ]
      },
      {
        topTitle: 'Wedden op je favoriete sport',
        buttonTitle: 'Sport',
        buttonBackgroundColor: 'customGreen',
        redirectionUrl: 'F',
        imageLeft: false,
        imageUrl: '/placeholders/sports.webp',
        title: 'Sports betting',
        body: 'Golden Palace Sports heeft een officiële licentie voor sportweddenschappen, zowel online als in een van onze vele Press Shop & More-winkels. Kies je favoriete sport, plaats een bet en profiteer van de beste odds voor maximale winstkansen. Wedden op voetbal, darts, basketbal, tennis, wielrennen, volleybal, ijshockey, American football, snooker, MMA, Esports... Op Golden Palace beleef je de kick van je leven als de ploeg waarop je een weddenschap hebt geplaatst met winst gaat lopen. Hadden we al gezegd dat we met de beste odds op de favorieten uitpakken? Daarmee weet je meteen genoeg...',
        smallArticles: [
          {
            title: 'Wedden op Voetbal',
            body: 'Als je nationaal of internationaal voetbal op de voet volgt, beleef je die passie nog een stuk heviger door in te zetten op de teams van jouw voorkeur. Dit kan zelfs live, tijdens de wedstrijd zelf. Wed op het aantal doelpunten of op de winnende ploeg en geef op die manier een extra dimensie aan je beleving van de verschillende matchen. Dankzij onze uiterst interessante odds wordt voetbal een nóg groter feest!',
            imageUrl: '/placeholders/hub-article-1.webp'
          },
          {
            title: 'Wedden op Wielrennen',
            body: "Wielrennen is 'big business' in België, en daar kan je ook zelf succesvol aan deelnemen. Niet door op een fiets te kruipen, wel door te wedden op de wielrenner waarvan jij verwacht dat die als eerste over de meet rijdt. Uithouding heb je daar gelukkig niet voor nodig, wel het juiste inzicht in de juiste renner of ploeg. Ben jij zo'n kenner? Of ben je een geboren geluksvogel? Wel dan...",
            imageUrl: '/placeholders/hub-article-2.webp'
          },
          {
            title: 'Wedden op Basketbal',
            body: 'Fan van dunks, lay-ups en driepunters? Bezeten door rebounds, steals, turnovers en overtime? Op de hoogte van de laatste ontwikkelingen in de Belgische, Europese en/of de Amerikaanse competitie? Op Golden Palace beleef je je persoonlijke basketbaldroom zoals nergens anders. Plaats de juiste bets en win wat er te winnen valt!',
            imageUrl: '/placeholders/hub-article-3.webp'
          }
        ]
      }
    ],
    advantages: {
      title: 'Waarom Golden Palace Casino & Sports kiezen?',
      cards: [
        {
          imageUrl: '/icons/promo.svg',
          title: 'Constant promos',
          body: "Op Golden Palace regent het promo's en die vallen altijd in de smaak bij onze trouwe klanten. Zij weten heel goed waarom, want er is steevast sprake van een grote prijzenpot. Onze tip: schrijf je als de bliksem in voor onze promo-nieuwsbrief..."
        },
        {
          imageUrl: '/icons/doublecards.svg',
          title: 'GoldenOdds',
          body: 'Golden Palace staat bekend om zijn GoldenOdds. Dat zijn gebooste odds die je potentiële winst doet toenemen, terwijl de situatie natuurlijk niet verandert. Kortom, dankzij de GoldenOdds kan je op Golden Palace meer en makkelijker winnen!'
        },
        {
          imageUrl: '/icons/personalCard.svg',
          title: 'Spannende toernooien',
          body: 'Golden Palace organiseert zo goed als dagelijks toernooien met extra veel prijzengeld, zowel voor casinogames als voor sportweddenschappen. Maak dus zeker gebruik van ons uitgebreide en kwalitatieve aanbod!'
        },
        {
          imageUrl: '/icons/hp.svg',
          title: 'Bijzonder toegankelijk',
          body: 'Bij ons hoef je geen ellenlange procedure te doorlopen om te kunnen wedden of spelen. Schrijf je in, passeer de leeftijdscheck en beland in een 100% veilige en legale spelomgeving. Golden Palace voldoet natuurlijk aan de strengste eisen van de Belgische Kansspelcommissie.'
        },
        {
          imageUrl: '/icons/heart.svg',
          title: 'Blijf op de hoogte',
          body: "Dankzij onze nieuwsbrieven en verschillende socialemediakanalen blijf je op de hoogte van de nieuwste games, de laatste ontwikkelingen, de interessantste promo's, GoldenOdds en meer. Hou je mailbox in de gaten en check regelmatig onze accounts voor nóg meer kansen."
        },
        {
          imageUrl: '/icons/slot.svg',
          title: 'We helpen je graag!',
          body: "Onze supportdienst staat altijd voor je klaar om op je vragen te antwoorden. Hulp nodig bij een bepaalde game? Of info over onze diensten? Als familiebedrijf staan we dichter bij onze spelers dan wie dan ook. Zowel online als in onze landbased casino's staat de klant centraal!"
        }
      ]
    },
    gamingHall: {
      imageUrl: '/placeholders/gaming-halls.webp',
      title: 'Golden Palace Gaming Halls',
      body: 'Waar je ook bent, er is altijd een Golden Palace in de buurt. Laat je onderdompelen en beleef onvergetelijke uren in een van onze tientallen speelzalen en casino’s in België en Frankrijk. Dinsdagmiddag, vrijdagochtend, zondagnacht... Er is altijd wel een Golden Palace in jouw buurt open en klaar om je telkens opnieuw met klasse te ontvangen.',
      buttonTitle: 'Ontdek',
      redirectionUrl: '/halls'
    }
  }
};

export default contentData;
